<template>
  <div
    class="min-h-screen lg:flex overflow-hidden pb-20 mb-2 lg:pb-0 antialiased bg-black"
  >
    <InvestorsNav v-if="portalMode !== 'esop' && portalMode !== 'founder'" />

    <EsopNav v-if="portalMode === 'esop'" />

    <FounderNav v-if="portalMode === 'founder'" />

    <TopMobileNav v-if="portalMode !== 'esop' && portalMode !== 'founder'" />

    <EsopTopMobileNav
      v-if="portalMode === 'esop'"
      :section-color="sectionColor"
    />

    <FounderTopMobileNav
      v-if="portalMode === 'founder'"
      :section-color="sectionColor"
    />

    <div class="flex flex-col flex-1 overflow-hidden">
      <div class="my-3 mx-0 md:mx-4 bg-transparent md:bg-zinc-900 rounded-full pl-0 md:pl-3">
        <Menubar
          v-if="$page.props.user"
          class="min-h-10 md:min-h-20 block md:flex items-center"
        >
          <template #start>
            <slot name="top-bar" />
            <SectionNav
              v-if="sectionLinks?.length > 0"
              :links="sectionLinks"
              :section-color="sectionColor"
              :disable-links="disableLinks"
            />
          </template>
          <template #end>
            <div class="relative hidden lg:flex space-x-4">
              <div class="hidden xl:flex">
                <EntitiesSelection
                  v-if="$page.props.user?.raw_entities.length > 1"
                />
              </div>

              <button
                class="cursor-pointer text-white"
                aria-haspopup="true"
                aria-controls="overlay_menu"
                @click="toggleMenu"
              >
                <i class="fa-regular fa-gear mr-10 mt-2" />
              </button>

              <PrimeMenu
                id="overlay_menu"
                ref="menu"
                :model="items"
                :popup="true"
                :show-transition-options="menuOptions"
                class="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50"
              >
                <template #start>
                  <span class="inline-flex items-center p-2 ml-3 text-sm uppercase text-zinc-400">
                    <span class="">{{ $page.props.user.name }}</span>
                  </span>
                </template>

                <template #item="{ item, props }">
                  <Link
                    class="flex items-center"
                    :href="item.route"
                    v-bind="props.action"
                  >
                    <i :class="props.icon.class" />
                    <span class="ml-2 text-sm">{{ item.label }}</span>
                  </Link>
                </template>
              </PrimeMenu>
            </div>
          </template>
        </Menubar>
      </div>

      <main class="bg-white h-full rounded-3xl mx-0 md:mx-4 shadow-2xl drop-shadow-xl z-10 pb-36 ">
        <slot name="before-nav" />
        <div :class="layoutClasses">
          <div class="">
            <INotification
              v-if="$page.props.flash.message"
              :type="$page.props.flash.type"
              :content="$page.props.flash.message"
            >
              {{ $page.props.flash.message }}
            </INotification>
          </div>
          <slot name="after-nav" />
          <slot />
        </div>
      </main>

      <slot name="after-content" />
    </div>

    <InvestorsMobileNav
      v-if="portalMode != 'esop' && portalMode != 'founder'"
    />
    <EsopMobileNav 
      v-if="portalMode == 'esop'" 
    />
    <FounderMobileNav 
      v-if="portalMode == 'founder'" 
    />
  </div>
</template>

<script>
import EsopMobileNav from "@/Shared/Components/Nav/EsopMobileNav.vue";
import EsopTopMobileNav from "@/Shared/Components/Nav/EsopTopMobileNav.vue";
import EsopManagementNav from "@/Shared/Components/Nav/EsopManagementNav.vue";
import FounderNav from "@/Shared/Components/Nav/FounderNav.vue";
import FounderMobileNav from "@/Shared/Components/Nav/FounderMobileNav.vue";
import InvestorsNav from "@/Shared/Components/Nav/InvestorsNav.vue";
import EsopNav from "@/Shared/Components/Nav/EsopNav.vue";
import TopMobileNav from "@/Shared/Components/Nav/TopMobileNav.vue";
import FounderTopMobileNav from "@/Shared/Components/Nav/FounderTopMobileNav.vue";
import SectionNav from "@/Shared/Components/Nav/SectionNav.vue";
import INotification from "@/Shared/Components/Notification/INotification.vue";
import InvestorsMobileNav from "@/Shared/Components/Nav/InvestorsMobileNav.vue";
import Menubar from "primevue/menubar";
import Menu from "primevue/menu";
import * as Sentry from "@sentry/browser";
import EntitiesSelection from "@/Shared/Components/Elements/EntitiesSelection.vue";

export default {
  components: {
    EntitiesSelection,
    InvestorsMobileNav,
    INotification,
    SectionNav,
    FounderTopMobileNav,
    TopMobileNav,
    EsopNav,
    InvestorsNav,
    FounderNav,
    EsopManagementNav,
    EsopTopMobileNav,
    EsopMobileNav,
    FounderMobileNav,
    Menubar,
    PrimeMenu: Menu,
  },
  props: {
    sectionColor: String,
    sectionLinks: Array,
    disableLinks: Boolean,
    layoutClasses: {
      type: String,
      default: "max-w-7xl mx-auto px-4 pb-6 lg:px-10",
    },
    portalMode: String,
    esopManagement: Boolean,
  },
  metaInfo: {
    titleTemplate: "Icehouse Ventures | %s",
  },
  data() {
    return {
      displayMenu: true,
      items: [
        {
          label: "Settings",
          icon: "fa-regular fa-gear",
          route: route("investors.settings")
        },
        {
          label: "Sign Out",
          icon: "fa-solid fa-arrow-right-to-bracket",
          route: route("logout"),
        }
      ],
      menuOptions: "opacity: 0.75"
    };
  },
  mounted() {
    if (this.$page.props.user && !this.$page.props.user.is_impersonating) {
      Sentry.setUser({
        id: this.$page.props.user.id,
        email: this.$page.props.user.email,
        username: this.$page.props.user.name,
      });
      this.$posthog.identify(
        this.$page.props.user.id,
        {
          email: this.$page.props.user.email,
          name: this.$page.props.user.name,
        }
      );
    }
  },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    }
  },
};
</script>
