<template>
  <nav class="min-h-screen hidden lg:flex lg:shrink-0">
    <div
      class="h-full flex flex-col w-64 transition ease-in-out duration-300"
      :class="'bg-' + sectionColor"
    >
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center shrink-0 my-4">
          <Link
            :href="route('esop.home')"
            class="pl-6"
          >
            <img
              src="/images/logo-medium.png"
              alt="Icehouse Ventures Logo"
              width="150"
            >
          </Link>
        </div>

        <nav
          v-if="$page.props.user"
          class="flex-1"
        >
          <p class="group flex items-center pl-6 pt-8 border-l-4 border-transparent text-lg font-bold leading-5 text-white pb-5">
            Equity
          </p>

          <Link
            :href="route('esop.home')"
            :class="{ 'font-medium bg-white font-bold bg-opacity-[0.2]': route().current('esop.home') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            <p class="pl-1">
              Overview
            </p>
          </Link>

          <Link
            :href="route('esop.grants.index')"
            :class="{ 'font-medium bg-white font-bold bg-opacity-[0.2]': route().current('esop.grants.*') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            <p class="pl-1">
              Grants
            </p>
          </Link>

          <Link
            :href="route('esop.exercises.index')"
            :class="{ 'font-medium bg-white font-bold bg-opacity-[0.2]': route().current('esop.exercises.*') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            <p class="pl-1">
              Exercises
            </p>
          </Link>

          <Link
            :href="route('esop.documents.index')"
            :class="{ 'font-medium bg-white font-bold bg-opacity-[0.2]': route().current('esop.documents.*') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            <p class="pl-1">
              Documents
            </p>
          </Link>

          <hr class="w-52 my-2 h-[0.5px] mx-auto border-0 bg-white bg-opacity-[0.2]">

          <Link
            :href="route('investors.home')"
            :class="{ 'font-medium border-white': route().current('investors.home') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-lg leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            Investments
          </Link>

          <div v-if="$page.props.user.has_raise_company">
            <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2]">

            <Link
              :href="route('raise.company')"
              :class="{ 'font-medium border-white': route().current('investors.raise.*') }"
              class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-lg leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
            >
              Manage Company
            </Link>
          </div>

          <div v-if="$page.props.user.has_fund_operator">
            <hr class="w-52 my-2 h-[0.5px] mx-auto  border-0 bg-white bg-opacity-[0.2]">

            <Link
              :href="route('operator.overview')"
              :class="{ 'font-medium border-white': route().current('operator.*') }"
              class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-lg leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
            >
              Manage Fund
            </Link>
          </div>

          <hr class="w-52 my-2 h-[0.5px] mx-auto border-0 bg-white bg-opacity-[0.2]">

          <Link
            :href="route('investors.settings')"
            :class="{ 'font-medium border-white': route().current('investors.settings') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            <p class="">
              Settings
            </p>
          </Link>

          <Link
            :href="route('logout')"
            :class="{ 'font-medium border-white': route().current('logout') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none transition ease-in-out duration-150"
          >
            Logout
          </Link>

          <a
            v-if="$page.props.user?.is_admin"
            href="/admin"
            :class="{ 'font-medium border-white': route().current('investors.settings') }"
            class="group flex items-center pl-6 py-3 border-l-4 border-transparent text-base leading-5 text-white focus:outline-none hover:border-white transition ease-in-out duration-150"
          >
            Staff Portal
          </a>
        </nav>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  components: {},
  props: {
    sectionColor: String
  },
  data: () => ({
    userMenuOpen: false,
    mobileNavOpen: false,
  }),
};
</script>
